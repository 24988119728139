//借还记录未还清
<template>
  <div class="examRule p-t-30">
    <el-dialog
        title=""
        :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="532px"
    >
      <div class="examRule_head flex ">
        <span>借还记录</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main">
        <div class="record_history">
          <div class="history_item"  v-for="(item, index) in 6" :key="index">
            <div class="item-li"><span class="color-9">领取时间：</span> 2021-1-08 14:14</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      content:'',//借还记录
    }
  },
  methods:{
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open () {
      this.$axios.kaoShi().then(res=>{
        this.dialogVisible = true
        this.content = res.data.content
      })
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog{border-radius:8px;overflow:hidden}

// .examRule_main {
//   // line-height: 28px;
// }
.record_history{
  padding:20px;
  .history_item{border-bottom:1px solid #EBEBEB;padding:10px 20px 20px 20px;font-size:16px;display:flex;justify-content:space-between;line-height:1.8}
  .history_item:nth-last-child(1){border:none;}
}
</style>
