// 还钥匙
<template>
  <div class="examRule p-t-30">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div class="examRule_head flex">
        <span>还钥匙</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form
            ref="form"
            :rules="rules"
            class="form"
            :model="form"
            label-width="100px"
          >
            <el-form-item label="姓名:" prop="user_name">
              <el-select @change="changeName" v-model="form.user_name" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in userList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.user_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工号:" prop="user_id">
              <el-input
                disabled
                v-model="form.user_id"
                placeholder="请输入工号"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号:" prop="mobile">
              <el-input
                disabled
                v-model="form.mobile"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">确定归还</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        user_id: "",
        user_name: "",
        mobile: "",
        fang_id: "",
        fang_type: "",
        id:''
      },
      rules:{
        user_name:[
          { required: true, message: '请选择姓名', trigger: 'blur' }
        ],
        user_id:[
          { required: true, message: '请输入工号', trigger: 'blur' }
        ],
        mobile:[
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ]
      },
      userList:[],//用户列表
    };
  },
  methods: {
    //选择名字
    changeName (e) {
      this.userList.forEach(item=>{
        if (e == item.user_id) {
          this.form.user_id = e
          this.form.user_name = item.name
          this.form.mobile = item.mobile
        }
      })
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
         this.$axios.keyUpdate(this.form).then(res=>{
           this.$message({
              message: res.msg,
              type: 'success'
            });
            this.form.user_name = ''
            this.form.user_id = ''
            this.form.mobile = ''
            this.dialogVisible = false
            this.$emit('succeed')
         })
        } else {
          return false;
        }
      });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(fang_id,type,id) {
      this.form.fang_id = fang_id
      this.form.id = id
      this.form.fang_type = type
      this.$axios.userList().then(res=>{
        this.userList = res.data
        this.dialogVisible = true;
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
  .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}
/deep/ .el-select {
  width: 100%;
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
