//借还记录已还清
<template>
  <div class="examRule p-t-30">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="532px"
    >
      <div class="examRule_head flex">
        <span>借还记录</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main">
        <div class="record_history">
          <div class="history_item" v-for="(item, index) in list" :key="index">
            <div class="item-li">
              <span class="color-9">领取时间：</span> {{ item.createtime }}
            </div>
            <div class="item-li">
              <span class="color-9">归还时间：</span> {{ item.updatetime }}
            </div>
          </div>
          <div class="paging" v-if="list.length">
            <el-pagination
              background
              layout="prev, pager, next, jumper, ->, total"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total:0,
      dialogVisible: false,
      list: [], //借还记录
      from:{
        fang_type:'',
        fang_id:'',
        page:1
      }
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    getList () {
      this.$axios.keyJieList(this.from).then((res) => {
        this.dialogVisible = true;
        this.list = res.data.data;
        this.total = res.data.total
      });
    },
    //打开
    open(id,type) {
      this.from.fang_type = type
      this.from.fang_id = id
      this.getList()
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.record_history {
  padding: 20px;
  .history_item {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px 20px 20px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    line-height: 1.8;
  }
  .history_item:nth-last-child(1) {
    border: none;
  }
}
</style>
