import { render, staticRenderFns } from "./KeyReturn.vue?vue&type=template&id=63ed104a&scoped=true&"
import script from "./KeyReturn.vue?vue&type=script&lang=js&"
export * from "./KeyReturn.vue?vue&type=script&lang=js&"
import style0 from "./KeyReturn.vue?vue&type=style&index=0&id=63ed104a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ed104a",
  null
  
)

export default component.exports